<template>
  <div class="landing">
    <div class="banner-wrapper" v-bind:style="{ backgroundImage: `url(${require('@/assets/images/sports-bg.jpg')})`, backgroundSize: 'cover' }">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="/"><img src="@/assets/images/logo_450-340.png" width="120" height="90" alt="logo"></a>
            </nav>
          </div>
        </div>
        <div class="row py-sm-5 py-0">
          <div class="col-lg-9 banner-content">
            <h4 class="mr-2 text-white"> {{$t('landing.sub')}} </h4>
            <h1 class="mr-2 text-white"> {{$t('landing.header')}} </h1>
            <h4 class="text-white pt-2 pb-1" v-html="$t('landing.lead')"></h4>
            <div class="my-5">
              <b-button v-if="loaded && !IsLoggedIn" v-b-modal.signin-modal variant="inverse-warning" class="btn btn-lg mr-4 mb-3">{{$t('becomeInfluencer').toUpperCase()}}</b-button>
              <b-button v-if="loaded && !IsLoggedIn" v-b-modal.signin-modal variant="inverse-info" class="btn btn-lg mb-3">{{$t('followInfluencer').toUpperCase()}}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="details-wrapper bg-dark">
      <div class="container">
        <section id="section-hero-details">
          <div class="row my-5 px-5">
            <div>Are you terrific at sports betting? Would you like to share your winning story and motivate fellow bettors on their journey to success. Do you love to transform the sports betting experience and discover the best sports betting tools to differentiate yourself and achieve long-term success. Apply to become a GoodBettor influencer today!</div>
          </div>
        </section>
      </div>
    </div>

    <div class="details-wrapper">
      <div class="container">
        
        <section  id="section3">
          <div class="row pt-5">
            <div class="col-md-12 pb-5 text-center text-white">
              <h1>{{$t('topfeature.header')}}</h1>
              <h1 class="mt-3"><span class="highlight danger" v-html="$t('topfeature.header2')"></span></h1>
              <div class="feature-description mt-5" v-html="$t('topfeature.lead')"></div>
            </div>

            <div class="feature-list mt-5">
              <div class="row feature-list-row text-center">
                <div class="col-lg-4 feature-list-item border-right p-4">
                  <i class="mdi mdi-strategy icon-lg text-warning"></i>
                  <h3>{{$t('topfeature.title1')}}</h3>
                  <p class="feature-description mt-5">{{$t('topfeature.sub1')}}</p>
                </div>
                <div class="col-lg-4 feature-list-item border-right p-4">
                  <i class="mdi mdi-book-open-page-variant text-primary"></i>
                  <h3>{{$t('topfeature.title2')}}</h3>
                  <p class="feature-description mt-5">{{$t('topfeature.sub2')}}</p>
                </div>
                <div class="col-lg-4 feature-list-item p-4">
                  <i class="mdi mdi-battery-charging-high text-danger"></i>
                  <h3>{{$t('topfeature.title3')}}</h3>
                  <p class="feature-description mt-5">{{$t('topfeature.sub3')}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row text-center justify-content-center my-5">
            <div class="col-md-12"><button v-if="loaded && !IsLoggedIn" v-b-modal.signin-modal class="btn btn-warning btn-lg mb-3 slide btn-xl">{{$t('becomeInfluencer').toUpperCase()}}</button></div>
            <div class="col-md-12"><button v-if="loaded && !IsLoggedIn" v-b-modal.signin-modal class="btn btn-info btn-lg slide btn-xl">{{$t('followInfluencer').toUpperCase()}}</button></div>
          </div>
        </section>

      </div>
    </div>

    <div class="details-wrapper bg-dark" v-bind:style="{ backgroundImage: `url(${require('@/assets/images/landing-sea-bg.jpg')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }">
      <div class="container banner-wrapper">
        <section id="section-hero-details">
          <div class="row">
            <div class="col-lg-8 banner-content">
              <h4 class="mr-2 text-white"> {{$t('landing.freeDownloadSection.sub')}} </h4>
              <h1 class="mr-2 text-white"> {{$t('landing.freeDownloadSection.title1')}} </h1>
              <h1 class="mr-2 text-white"> {{$t('landing.freeDownloadSection.title2')}} </h1>
              <h1 class="mr-2 text-white"> {{$t('landing.freeDownloadSection.title3')}} </h1>
              <h4 class="text-white pt-2 pb-1" v-html="$t('landing.freeDownloadSection.lead')"></h4>
              <div class="mt-5">
                <b-button href="/files/Creating_a_Long_term_income_structure.pdf" target="_blank" class="btn btn-warning btn-lg mr-4 mb-3 text-dark text-500">{{$t('landing.freeDownloadSection.button')}}</b-button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>


    <footer>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-4 col-sm-12 mt-3">
            <h5 class="text-200 mb-0">&copy; {{new Date().getFullYear()}} <a href="javascript:void(0);" class="text-info">GoodBettor</a>.</h5>
          </div>

          <div class="col-md-4 col-sm-12 mt-3">
            <h5 class="mb-0"><a href="mailto:support@goodbettor.com" class="text-info">Contact Us</a></h5>
          </div>

          <div class="col-md-4 col-sm-12 mt-3">
            <div class="d-flex justify-content-center">
            <div v-for="social in socialList" v-bind:key="social" class="social-icons text-white">
              <span :class="'mdi mdi-' + (social == 'kakao' ? 'forum' : social)" @click="openSocial(social)"></span>
            </div>
          </div>
          </div>
        </div>
      </div>
    </footer>
    <register />
    <signin />
  </div>
</template>

<script>
import register from '@/pages/auth/register.vue'
import signin from '@/pages/auth/signin.vue'

export default {
  name: 'Landing',
  components: {
    register,
    signin
  },
  data() {
    return {
      socialList: ['instagram','facebook', 'twitter','kakao'],
      loaded: false
    }
  },
  mounted: function () {
    this.$bobwinHub.closeHub();
    this.loaded = true;
  },
  methods: {
    openSocial(type) {
      let url = '';
      switch(type){
        case 'telegram':
          url = 'https://t.me/BOBwincommunity';
          break;
        case 'twitter':
          url = 'https://twitter.com/realgoodbettor';
          break;
        case 'discord':
          url = 'https://discord.com/invite/WbGxhVGca2';
          break;
        case 'kakao':
          url = 'https://open.kakao.com/o/gWbPcO4e';
          break;
        case 'instagram':
          url = 'https://www.instagram.com/goodbettor/';
          break;
        case 'facebook':
          url = 'https://www.facebook.com/groups/928020308612820/';
          break;
      }
      window.open(url,'_blank');
    },
  }
}
</script>